<template>
  <div data-app>
    <v-card :loading="loadingTree">
      <v-card-title>
        <h3 style="color: #e33354" class="ml-3">
          {{ cardTitle }}
          <TooltipModal
            :title="$t('ALERT.info_title')"
            :text="$helpers.getActiveSiteI18Property('INFO_BOX.PAGES')"
          ></TooltipModal>
        </h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="2" md="2">
            <v-btn
              v-if="permissionCan('create')"
              color="primary"
              dark
              class="mb-2 ml-3 mt-4"
              v-bind="attrs"
              v-on="on"
              @click="handleNew"
            >
              {{ $t("MENU.NEW") }} {{ $t("MENU.PAGE") }}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
              @input="handleSearch"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="1" md="1">
            <v-btn-toggle dense mandatory class="mt-4 float-right">
              <v-btn @click.stop="handleChangeViewType">
                <v-icon v-if="viewType == 'tree'"> mdi-view-headline </v-icon>
                <v-icon v-else> mdi-file-tree</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <PageModalForm
              :modalData="modalData"
              :permissions="permissions"
              :statuses="statuses"
              :endPoint="endPoint"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
            ></PageModalForm>
            <v-treeview
              v-if="viewType == 'tree'"
              open-all
              :items="computedPageCollection"
              item-key="id"
              dense
              item-children="children"
              hoverable
            >
              <template v-slot:prepend="{ item, open }">
                <v-icon
                  v-if="
                    typeof item.translations[appLocale] != 'undefined' &&
                    item.translations[appLocale]
                  "
                  color="green"
                  small
                  >check_circle</v-icon
                >
              </template>

              <template slot="label" slot-scope="{ item }">
                <span @click="handleClickItem(item)" style="cursor: pointer">
                  {{ $helpers.getTranslated(item.translations).name }}
                </span>
              </template>

              <template v-slot:append="{ item }">
                <v-icon
                  color="primary"
                  v-if="permissionCan('delete')"
                  small
                  @click.stop="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-treeview>
            <v-data-table
              v-else
              :headers="headers"
              :items="pageCollection"
              :search="search"
              :sort-by="['id']"
              sort-desc="true"
              :loading="loadingTable"
              @click:row="handleClickItem"
              :footer-props="{
                'items-per-page-options': [100],
              }"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <PageModalForm
                    :modalData="modalData"
                    :permissions="permissions"
                    :statuses="statuses"
                    :endPoint="endPoint"
                    @closeModalForm="handleCloseModalForm"
                    @saveModalForm="handleSaveModalForm"
                    @new="handleNew"
                  ></PageModalForm>
                  <v-spacer></v-spacer>
                  <DeleteModalDialog
                    :dialogDelete="dialogDelete"
                    @closeDelete="handleCloseDelete"
                    @deleteItemConfirm="handleDeleteItemConfirm"
                  >
                  </DeleteModalDialog>
                </v-toolbar>
              </template>
              <template v-slot:item.translated="{ item }">
                <v-icon v-if="item.translated" color="green" small
                  >check_circle</v-icon
                >
              </template>
              <template v-slot:item.status="{ item }">
                {{ $t("STATUSES." + statuses.pages.page[item.status].value) }}
              </template>
              <template v-slot:item.parentName="{ item }">
                <span>
                  {{ getParentName(item) }}
                </span>
              </template>
              <template v-slot:item.updated_at="{ item }">
                {{
                  new Date(item.updated_at).toLocaleString("hu-HU", {
                    timeZone: "UTC",
                  })
                }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-icon
                  color="primary"
                  v-if="permissionCan('delete')"
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <DeleteModalDialog
          :dialogDelete="dialogDelete"
          @closeDelete="handleCloseDelete"
          @deleteItemConfirm="handleDeleteItemConfirm"
        >
        </DeleteModalDialog>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SiteService from "@/core/services/site.service.js";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";
// import TooltipModal from "@/view/components/tooltipModal.vue";

import PageModalForm, { initialFormData } from "./PageModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

import i18nService from "@/core/services/i18n.service.js";

export const PERMISSION_TO = "pages.page.";

export default {
  name: "Pages",
  // components: { PageModalForm, DeleteModalDialog, TooltipModal },
  components: { PageModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      appLocale: i18nService.getUserActiveLanguage(),
      cardTitle: this.$t("MENU.PAGES"),
      search: "",
      routePath: "/cms/pages/pages/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: this.$t("TABLE.translated"),
          value: "translated",
          width: "100px",
        },
        {
          text: this.$t("TABLE.page"),
          value: "name",
        },
        {
          text: this.$t("TABLE.parent"),
          value: "parentName",
        },
        {
          text: this.$t("TABLE.updated_at"),
          value: "updated_at",
        },
        { text: this.$t("TABLE.status"), value: "status" },
        {
          text: this.$t("TABLE.actions"),
          value: "actions",
          sortable: false,
          width: "100px",
        },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
      loadingTree: true,

      viewType: "tree",
      viewTypeSelectFrom: "tree",
    };
  },

  computed: {
    ...mapGetters(["pageCollection", "getPageByID", "statuses"]),

    endPoint() {
      return "pages/admin/" + SiteService.getActiveSiteId() + "/page/";
    },
    computedPageCollection() {
      return this.createDataForTreeselect(this.pageCollection);
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchPage"]),
    fetchModel() {
      return this.fetchPage();
    },
    createDataForTreeselect(layer, page_id = null) {
      const vertex = new Map(),
        others = [];
      layer.forEach((item) => {
        if (item.page_id === page_id) {
          vertex.set(item.id, item);
        } else {
          others.push(item);
        }
      });
      for (const vertexId of vertex.keys()) {
        const children = this.createDataForTreeselect(others, vertexId);
        if (children.length) {
          vertex.get(vertexId).children = children;
        }
      }
      return [...vertex.values()];
    },

    handleSaveModalForm(id = null) {
      if (id) {
        this.modalData.editedId = null;
        this.modalData.editedId = id;
      }
      // this.handleCloseModalForm();
    },

    handleChangeViewType() {
      if (this.viewType == "tree") {
        this.viewType = "list";
        this.viewTypeSelectFrom = "list";
      } else {
        this.viewType = "tree";
        this.viewTypeSelectFrom = "tree";
      }
    },

    handleSearch() {
      this.$nextTick(() => {
        if (this.search.length > 0) {
          this.viewType = "list";
        } else {
          this.viewType = this.viewTypeSelectFrom;
        }
      });
    },

    getParentName(item) {
      if (!item.page_id) return null;

      let parentpage = this.getPageByID(item.page_id);
      return this.$helpers.getTranslated(parentpage.translations).name;
    },
  },

  mounted() {
    this.fetchModel().then(() => (this.loadingTree = false));
  },
};
</script>
