<template>
  <v-dialog
    v-model="modalData.dialog"
    :max-width="modalWidth + 'px'"
    persistent
    :retain-focus="false"
    scrollable
  >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }}</span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col :cols="computedCols" style="overflow-y: scroll; height: 70vh">
            <v-form v-model="formValid" ref="form">
              <v-row>
                <v-spacer></v-spacer>

                <v-col cols="12" sm="2" md="2" class="mt-3 text-right">
                  <v-btn
                    color="primary"
                    outlined
                    v-if="canImportMedia"
                    @click="handleImportMedia"
                    >Media import</v-btn
                  >
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-select
                    v-model="selectedLocale"
                    :items="languages"
                    :disabled="languages.length == 1"
                  >
                    <template slot="selection" slot-scope="slotProps">
                      <span class="symbol symbol-20 mr-3">
                        <img :src="selectedLocale.flag" alt="" />
                      </span>
                      {{ slotProps.item.name }}
                    </template>
                    <template v-slot:item="slotProps">
                      <span class="symbol symbol-20 mr-3">
                        <img :src="slotProps.item.flag" alt="" />
                      </span>
                      <span class="navi-text">{{ slotProps.item.name }}</span>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" sm="2" md="2">
                  <v-select
                    v-if="statuses.pages"
                    v-model="formModel.status"
                    :items="statuses.pages.page"
                    :label="$t('FORMS.status')"
                    item-text="value"
                    item-value="key"
                  >
                    <template slot="selection" slot-scope="slotProps">
                      {{ $t("STATUSES." + slotProps.item.value) }}
                    </template>
                    <template v-slot:item="slotProps">
                      <span class="navi-text">{{
                        $t("STATUSES." + slotProps.item.value)
                      }}</span>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3" md="3">
                  <v-text-field
                    :rules="nameRules"
                    v-if="formModel.translations[selectedLocale.lang]"
                    v-model="formModel.translations[selectedLocale.lang].name"
                    :key="selectedLocale.lang + '-name'"
                    :label="$t('FORM_INPUT_NAMES.title')"
                    :error-messages="
                      messages['translations.' + selectedLocale.lang + '.name']
                    "
                    @keyup="
                      messages[
                        'translations.' + selectedLocale.lang + '.name'
                      ] = ''
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-text-field
                    v-if="
                      formModel.id &&
                      formModel.translations[selectedLocale.lang] &&
                      formModel.template_file != 'main_page'
                    "
                    v-model="formModel.translations[selectedLocale.lang].slug"
                    :key="selectedLocale.lang + '-slug'"
                    :label="$t('FORMS.slug')"
                    :error-messages="
                      messages['translations.' + selectedLocale.lang + '.slug']
                    "
                    @keyup="
                      messages[
                        'translations.' + selectedLocale.lang + '.slug'
                      ] = ''
                    "
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="3" md="3">
                  <v-select
                    v-if="statuses.pages"
                    v-model="formModel.template_file"
                    :items="pageTemplateCollection"
                    :label="$t('.PAGE_TEMPLATE')"
                    item-text="name"
                    item-value="slug"
                  />
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <treeselect
                    v-model="formModel.page_id"
                    :clearable="clearable"
                    :searchable="searchable"
                    :options="computedParentPageCollection"
                    :placeholder="$t('FORMS.parent')"
                    :noChildrenText="false"
                    class="mt-4"
                  >
                  </treeselect>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-checkbox
                    v-model="formModel.fictional"
                    :label="$t('FORMS.fictional')"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <ckeditor
                    v-if="formModel.translations[selectedLocale.lang]"
                    v-model="formModel.translations[selectedLocale.lang].text"
                    :key="selectedLocale.lang + '-text'"
                    :error-messages="
                      messages['translations.' + selectedLocale.lang + '.text']
                    "
                    @input="
                      messages[
                        'translations.' + selectedLocale.lang + '.text'
                      ] = ''
                    "
                    :config="editorConfig"
                  ></ckeditor>
                  <small
                    v-if="
                      messages['translations.' + selectedLocale.lang + '.text']
                    "
                    style="color: red"
                  >
                    {{
                      messages["translations." + selectedLocale.lang + ".text"]
                    }}
                  </small>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col
                  v-for="(field, index) in templateJSON"
                  :key="index"
                  :class="getClass(field.config.initialColumnClass)"
                >
                  <v-row v-if="editCustomFieldMode" class="mt-5">
                    <v-col>
                      <v-btn
                        class="mt-2 mr-2"
                        outlined
                        x-small
                        fab
                        color="primary"
                        @click="handleAddCustomField(index)"
                      >
                        <!-- <v-icon>mdi-creation</v-icon> -->
                        <v-icon>mdi-playlist-plus</v-icon>
                      </v-btn>
                      <v-btn
                        class="mt-2 mr-2"
                        outlined
                        x-small
                        fab
                        color="primary"
                        @click="handleEditCustomField(index, field)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        class="mt-2 mr-2"
                        icon
                        x-small
                        fab
                        color="primary"
                        @click="handleCustomFieldUp(index)"
                        :disabled="index < 1"
                      >
                        <v-icon>mdi-arrow-up-bold</v-icon>
                      </v-btn>
                      <v-btn
                        class="mt-2 mr-2"
                        icon
                        x-small
                        fab
                        color="primary"
                        @click="handleCustomFieldDown(index)"
                        :disabled="index > templateJSON.length - 2"
                      >
                        <v-icon>mdi-arrow-down-bold</v-icon>
                      </v-btn>
                      <v-btn
                        class="mt-2"
                        outlined
                        x-small
                        fab
                        color="primary"
                        @click="handleDeleteCustomField(index)"
                      >
                        <!-- <v-icon>mdi-delete</v-icon> -->
                        <v-icon>mdi-delete-sweep</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <CustomFieldComponent
                    v-if="
                      field.config.type &&
                      formModel.translations[selectedLocale.lang] &&
                      formModel.translations[selectedLocale.lang]
                        .custom_fields &&
                      field.customFieldName != 'divider'
                    "
                    :customFieldName="field.customFieldName"
                    :config="field.config"
                    :value="
                      formModel.translations[selectedLocale.lang].custom_fields[
                        field.customFieldName
                      ] ||
                      field.config.defaultValue ||
                      ''
                    "
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                  <v-divider
                    v-if="field.customFieldName == 'divider'"
                  ></v-divider>
                  <h3 v-if="field.customFieldName == 'divider'">
                    {{ $t(field.config.label) }}
                  </h3>
                </v-col>
              </v-row>

              <v-divider v-if="list.length > 0"></v-divider>
              <v-row v-if="list.length > 0">
                <v-col cols="12">
                  {{ $t("FORM_INPUT_NAMES.subpages") }}
                  <draggable
                    class="list-group"
                    tag="ul"
                    v-model="list"
                    v-bind="dragOptions"
                    :move="onMove"
                    @start="isDragging = true"
                    @end="isDragging = false"
                  >
                    <transition-group type="transition" :name="'flip-list'">
                      <li
                        class="list-group-item"
                        v-for="element in list"
                        :key="element.id"
                      >
                        <i @click="changeFixed(element)" aria-hidden="true">
                          <span
                            class="mdi mdi-anchor"
                            v-if="element.fixed"
                          ></span>
                          <span
                            class="mdi mdi-arrow-all active"
                            style="padding-top: 10px"
                            v-else
                          ></span>
                        </i>
                        <span
                          class="sort-name"
                          :class="element.fixed ? '' : 'active'"
                          >{{ translate(element) }}</span
                        >
                      </li>
                    </transition-group>
                  </draggable>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12">
                  <CustomFieldComponent
                    v-if="
                      formModel.translations[selectedLocale.lang] &&
                      formModel.translations[selectedLocale.lang].custom_fields
                    "
                    :config="{
                      type: 'text',
                      label: 'FORMS.seo_title',
                      charLimit: 60,
                    }"
                    customFieldName="seoTitle"
                    :value="
                      formModel.translations[selectedLocale.lang].custom_fields
                        .seoTitle
                    "
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                </v-col>
                <v-col cols="12">
                  <CustomFieldComponent
                    v-if="
                      formModel.translations[selectedLocale.lang] &&
                      formModel.translations[selectedLocale.lang].custom_fields
                    "
                    :config="{
                      type: 'textarea',
                      label: 'FORMS.seo_description',
                      charLimit: 160,
                    }"
                    customFieldName="seoDescription"
                    :value="
                      formModel.translations[selectedLocale.lang].custom_fields
                        .seoDescription
                    "
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="8" md="8">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="mt-2">
                      <CustomFieldComponent
                        v-if="
                          formModel.translations[selectedLocale.lang] &&
                          formModel.translations[selectedLocale.lang]
                            .custom_fields
                        "
                        :config="{
                          type: 'text',
                          label: 'FORM_INPUT_NAMES.og_title',
                        }"
                        customFieldName="og_title"
                        :value="
                          formModel.translations[selectedLocale.lang]
                            .custom_fields.og_title || ''
                        "
                        @handleChangeCustomFieldValue="
                          handleChangeCustomFieldValue
                        "
                      >
                      </CustomFieldComponent>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <CustomFieldComponent
                        v-if="formModel && formModel.custom_fields"
                        :config="{
                          type: 'multi_select',
                          multiple: false,
                          label: $t('FORM_INPUT_NAMES.og_type'),

                          items: og_types,
                        }"
                        customFieldName="og_type"
                        :value="formModel.custom_fields.og_type || 'website'"
                        @handleChangeCustomFieldValue="
                          handleChangeCustomFieldValue
                        "
                      >
                      </CustomFieldComponent>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <CustomFieldComponent
                        v-if="
                          formModel.translations[selectedLocale.lang] &&
                          formModel.translations[selectedLocale.lang]
                            .custom_fields
                        "
                        :config="{
                          type: 'textarea',
                          label: 'FORM_INPUT_NAMES.og_description',
                        }"
                        customFieldName="og_description"
                        :value="
                          formModel.translations[selectedLocale.lang]
                            .custom_fields.og_description || ''
                        "
                        @handleChangeCustomFieldValue="
                          handleChangeCustomFieldValue
                        "
                      >
                      </CustomFieldComponent>
                    </v-col>
                    <v-col cols="12">
                      <CustomFieldComponent
                        v-if="formModel.custom_fields"
                        :config="{
                          type: 'text',
                          label: 'FORMS.canonical',
                          charLimit: 60,
                        }"
                        customFieldName="canonical"
                        :value="formModel.custom_fields.canonical"
                        @handleChangeCustomFieldValue="
                          handleChangeCustomFieldValue
                        "
                      >
                      </CustomFieldComponent>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <CustomFieldComponent
                    v-if="
                      formModel.translations[selectedLocale.lang] &&
                      formModel.translations[selectedLocale.lang].custom_fields
                    "
                    customFieldName="og_image"
                    :value="
                      formModel.translations[selectedLocale.lang].custom_fields
                        .og_image || ''
                    "
                    :config="{
                      type: 'media_selector',
                      maxFiles: 1,
                      selectButtonText: 'FORM_INPUT_NAMES.og_image',
                      initialColumnClass:
                        'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
                    }"
                    @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
                  >
                  </CustomFieldComponent>
                </v-col>
              </v-row>
            </v-form>

            <CustomFieldEditorModalDialog
              :customFieldEditorData="customFieldEditorData"
              @handleCloseCustomFieldEditorModalForm="
                handleCloseCustomFieldEditorModalForm
              "
              @handleSaveCustomFieldEditorModalForm="
                handleSaveCustomFieldEditorModalForm
              "
            >
            </CustomFieldEditorModalDialog>
            <DeleteModalDialog
              :dialogDelete="dialogDelete"
              @closeDelete="handleCloseDelete"
              @deleteItemConfirm="handleDeleteItemConfirm"
            >
            </DeleteModalDialog>

            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
              color="primary"
              :top="true"
            >
              <b style="font-size: 1.25rem">{{ warningText }}</b>

              <template v-slot:actions>
                <v-btn color="white" variant="text" @click="snackbar = false">
                  X
                </v-btn>
              </template>
            </v-snackbar>
          </v-col>

          <AIComponent
            :showAIBlock="showAIBlock"
            :selectedLocale="selectedLocale"
          ></AIComponent>
        </v-row>
      </v-card-text>
      <v-card-actions class="mb-2">
        <v-switch
          v-if="isSuperAdmin()"
          class="mt-5"
          v-model="editCustomFieldMode"
          :label="$t('FORM_INPUT_NAMES.editing_custom_field')"
        ></v-switch>
        <v-btn
          v-if="editCustomFieldMode"
          color="primary"
          class="ml-2"
          outlined
          @click="handleSaveTemplate"
        >
          {{ $t("FORM_INPUT_NAMES.save_template") }}
        </v-btn>

        <v-spacer></v-spacer>
        <!-- <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn> -->

        <v-switch
          v-if="synergyAssistant()"
          class="mt-5 mr-5"
          v-model="showAIBlock"
          inset
          :label="$t('PERMISSIONS.system_synergyfox_assistant')"
        ></v-switch>

        <PreviewURLComponent
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          slug="slug"
          :lang="selectedLocale.lang"
          type="page"
          :formModel="formModel"
          @handlePreview="handleSaveModalForm"
        ></PreviewURLComponent>

        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          :disabled="editCustomFieldMode"
          color="primary"
          elevation="2"
          @click="handleSaveModalForm(null)"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
      <SnackBarInfoComponent :snackbarInfo="snackbarInfo">
      </SnackBarInfoComponent>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./Pages";
import i18nService from "@/core/services/i18n.service.js";

import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";

import CustomFieldEditorModalDialog from "@/view/components/CustomFieldEditorModalDialog";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import SnackBarInfoComponent from "@/view/components/SnackBarInfoComponent";
import SiteService from "@/core/services/site.service.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import PreviewURLComponent from "@/view/components/PreviewURLComponent";

import draggable from "vuedraggable";

const INITIAL_CUSTOM_FIELD_EDITOR_DATA = {
  dialog: false,
  customField: null,
  editedIndex: null,
  addIndex: null,
  deleteIndex: null,
};

const TRANSLATED_ATRIBUTES = ["name", "slug", "text"];
export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
  "tours",
  "seoTitle",
  "seoDescription",
  "main_image",
  "template_file",
  "og_title",
  "og_description",
  "og_url",
  "og_site_name",
  "og_image",
];

export const INITIAL_CUSTOM_FIELDS = {
  og_type: "",
  canonical: "",
};

export const initialFormData = () => ({
  id: null,
  fictional: 0,
  page_id: null,
  status: 1,
  translations: {},
  custom_fields: INITIAL_CUSTOM_FIELDS,
});

export default {
  name: "PageForm",
  props: ["modalData", "permissions", "statuses", "endPoint"],
  components: {
    CustomFieldComponent,
    CustomFieldEditorModalDialog,
    DeleteModalDialog,
    Treeselect,
    SnackBarInfoComponent,
    PreviewURLComponent,
    draggable,
  },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      initialModalWidth: 1450,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],

      templateJSON: [],
      og_types: ["website", "article"],

      // custom fields editor
      customFieldEditorData: Object.assign(
        {},
        INITIAL_CUSTOM_FIELD_EDITOR_DATA
      ),
      editCustomFieldMode: false,
      dialogDelete: false,

      snackbar: false,
      text: "",
      timeout: 3000,

      editable: true,
      list: [],
    };
  },
  computed: {
    ...mapGetters(["pageTemplateCollection", "pageCollection", "currentUser"]),

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            " " +
            this.$helpers.getTranslated(this.formModel.translations).name
        : this.$t("MENU.NEW") + " " + this.$t("MENU.PAGE");
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
    computedParentPageCollection() {
      let tree = this.createDataForTreeselect(
        this.pageCollection,
        null,
        this.formModel.id
      );
      return tree;
    },
    tours() {
      return this.productCollection.filter(
        (item) =>
          item.product_category_id == process.env.VUE_APP_EGYEB_CATEGORY_ID
      );
    },
    isTour() {
      return (
        process.env.VUE_APP_TOURS_PAGE_ID == this.formModel.id &&
        this.formModel.id
      );
    },

    canImportMedia() {
      let canImport = true;

      this.templateJSON.forEach((field) => {
        if (
          field.config.type == "media_selector" &&
          this.formModel.translations &&
          this.formModel.translations[this.selectedLocale.lang] &&
          this.formModel.translations[this.selectedLocale.lang].custom_fields &&
          this.formModel.translations[this.selectedLocale.lang].custom_fields[
            field.customFieldName
          ] &&
          this.formModel.translations[this.selectedLocale.lang].custom_fields[
            field.customFieldName
          ].length > 0
        ) {
          canImport = false;
        }
      });

      return canImport && this.isSuperAdmin();
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: !this.editable,
        ghostClass: "ghost",
      };
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.snackbar = false;
        this.warningText = "";
        this.timeout = 3000;

        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        this.list = [];
        this.editCustomFieldMode = false;
        this.setTemplate("[]");
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              if (!data.custom_fields)
                data.custom_fields = INITIAL_CUSTOM_FIELDS;
              this.formModel = Object.assign({}, data);
              this.setTranslatedAttributes();

              if (!this.formModel.custom_fields.childs) {
                this.formModel.custom_fields.childs = [];
              }

              let allChilds = this.getChilds(this.formModel.id);

              allChilds.forEach((child) => {
                if (
                  !this.formModel.custom_fields.childs.find(
                    (item) => item.id == child.id
                  )
                ) {
                  this.formModel.custom_fields.childs.push(child);
                }
              });

              this.formModel.custom_fields.childs =
                this.formModel.custom_fields.childs.filter((child) =>
                  allChilds.find((item) => item.id == child.id)
                );
              this.list = this.formModel.custom_fields.childs.map((item) => {
                if (!item.fixed) {
                  item.fixed = false;
                }

                return item;
              });
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();

          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },

    "formModel.template_file": function (newVal) {
      if (newVal) {
        let item = this.pageTemplateCollection.find(
          (item) => item.slug == newVal
        );

        if (item) {
          this.setWarning(item.translations);
          this.setTemplate(item.fields);
        }
      }
    },
  },
  methods: {
    ...mapActions(["fetchPageTemplate", "fetchPage", "fetchProduct"]),

    handleSaveModalForm(previewURL = null) {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );
      if (typeof model.page_id === "undefined") {
        model.page_id = null;
      }

      this.$refs.form.validate();

      if (this.formValid) {
        this.loader = true;
        this.resetErrorMessages();

        if (model.id) {
          model.custom_fields.childs = this.list.map((page) => {
            return this.pageCollection.find((p) => p.id == page.id);
          });

          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              this.showSnackBarInfo();
              setTimeout(() => {}, 0);
              this.$emit("saveModalForm", model.id);
              if (previewURL && previewURL != "") {
                window.open(previewURL, "_blank");
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(this.endPoint, model)
            .then(({ data }) => {
              this.showSnackBarInfo();
              // this.$emit("saveModalForm", data);
              setTimeout(() => {}, 0);
              this.$emit("saveModalForm", data.id);

              if (previewURL && previewURL != "") {
                window.open(previewURL, "_blank");
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  // console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    setTemplate(template) {
      template = JSON.parse(template);
      this.initialTransaltedCustomFields = INITIAL_TRANSLATED_CUSTOM_FIELDS;
      if (template && Array.isArray(template)) {
        template.forEach((field) => {
          this.initialTransaltedCustomFields.push(field.customFieldName);
        });

        this.templateJSON = template;
      }
    },

    setWarning(template) {
      if (
        template &&
        template[this.selectedLocale.lang] &&
        template[this.selectedLocale.lang].custom_fields
      ) {
        this.snackbar =
          template[this.selectedLocale.lang].custom_fields.warningShow;
        this.warningText =
          template[this.selectedLocale.lang].custom_fields.warningText;
        this.timeout = 3000;
      }
    },

    getClass(configClass) {
      if (!configClass)
        return "col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12";
      return configClass;
    },

    createDataForTreeselect(layer, page_id = null, edited_id = null) {
      const vertex = new Map(),
        others = [];
      layer.forEach((item) => {
        if (item.page_id === page_id) {
          let isDisabled = false;
          if (
            edited_id &&
            (edited_id == item.id || edited_id == item.page_id)
          ) {
            isDisabled = true;
          }

          vertex.set(item.id, {
            id: item.id,
            label: this.$helpers.getTranslated(item.translations).name,
            isDisabled: isDisabled,
          });
        } else {
          others.push(item);
        }
      });
      for (const vertexId of vertex.keys()) {
        const children = this.createDataForTreeselect(
          others,
          vertexId,
          edited_id
        );
        if (children.length) {
          vertex.get(vertexId).children = children;
        }
      }
      return [...vertex.values()];
    },

    handleImportMedia() {
      let firstLocale = this.languages[0].lang;
      let formModel = Object.assign({}, this.formModel);
      this.templateJSON.forEach((field) => {
        if (
          ["media_selector", "repeater"].includes(field.config.type) &&
          this.formModel.translations &&
          this.formModel.translations[this.selectedLocale.lang] &&
          this.formModel.translations[this.selectedLocale.lang].custom_fields &&
          this.formModel.translations[firstLocale].custom_fields[
            field.customFieldName
          ]
        ) {
          formModel.translations[this.selectedLocale.lang].custom_fields[
            field.customFieldName
          ] = [];
          formModel.translations[firstLocale].custom_fields[
            field.customFieldName
          ].forEach((item) => {
            formModel.translations[this.selectedLocale.lang].custom_fields[
              field.customFieldName
            ].push(Object.assign({}, item));
          });
        }
      });

      this.formModel = Object.assign({}, formModel);
    },

    // showCustomFieldModal() {
    //   this.templateJSON.push({
    //     customFieldName: "button_team",
    //     config: {
    //       type: "button",
    //       label: "Team",
    //     },
    //   });

    //   console.log(this.formModel.template_file);
    //   console.log(this.templateJSON);
    // },

    handleAddCustomField(index) {
      // this.resetCustomFieldEditorData();
      this.customFieldEditorData.customField = null;
      this.customFieldEditorData.addIndex = index;
      // console.log("this.customFieldEditorData", this.customFieldEditorData);
      this.customFieldEditorData.dialog = true;
      // this.showCustomFieldModal();
    },

    handleEditCustomField(index, field) {
      this.customFieldEditorData.customField = Object.assign({}, field);
      this.customFieldEditorData.editedIndex = index;
      this.customFieldEditorData.dialog = true;
    },

    // Custom field editor
    resetCustomFieldEditorData() {
      this.customFieldEditorData = Object.assign(
        {},
        INITIAL_CUSTOM_FIELD_EDITOR_DATA
      );
    },

    handleCloseCustomFieldEditorModalForm() {
      this.resetCustomFieldEditorData();
    },

    handleSaveCustomFieldEditorModalForm(customField) {
      if (
        this.customFieldEditorData.editedIndex ||
        this.customFieldEditorData.editedIndex === 0
      ) {
        this.$set(
          this.templateJSON,
          this.customFieldEditorData.editedIndex,
          customField
        );
      } else {
        this.templateJSON.splice(
          this.customFieldEditorData.addIndex,
          0,
          customField
        );
      }

      this.resetCustomFieldEditorData();
    },

    handleSaveTemplate() {
      if (!this.formModel.template_file) {
        return null;
      }

      let pageTemplate = this.pageTemplateCollection.find(
        (item) => item.slug == this.formModel.template_file
      );

      if (pageTemplate) {
        pageTemplate.fields = JSON.stringify(this.templateJSON, null, 4);

        ApiService.put(
          this.endPointPageTemplate() + pageTemplate.id,
          pageTemplate
        )
          .then(() => {
            // this.$emit("saveModalForm");
            this.editCustomFieldMode = false;
            // this.fetchPageTemplate().then(() => {
            //   this.setTemplate(pageTemplate);
            // });
            this.handleCloseModalForm();
          })
          .catch((error) => {
            if (error.response) {
              let errors = error.response.data;
              if (errors) {
                for (let field in errors.errors) {
                  this.setError(field, errors.errors[field][0]);
                }
              }
            }
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },

    handleDeleteCustomField(index) {
      // console.log(index);
      this.customFieldEditorData.deleteIndex = index;
      this.dialogDelete = true;
    },

    handleCloseDelete() {
      this.customFieldEditorData.deleteIndex = null;
      this.dialogDelete = false;
    },

    handleDeleteItemConfirm() {
      this.templateJSON.splice(this.customFieldEditorData.deleteIndex, 1);
      this.handleCloseDelete();
    },

    handleCustomFieldUp(index) {
      if (index > 0) {
        let temp = this.templateJSON[index - 1];
        this.$set(this.templateJSON, index - 1, this.templateJSON[index]);
        this.$set(this.templateJSON, index, temp);
      }
    },

    handleCustomFieldDown(index) {
      if (index < this.templateJSON.length - 1) {
        let temp = this.templateJSON[index];
        this.$set(this.templateJSON, index, this.templateJSON[index + 1]);
        this.$set(this.templateJSON, index + 1, temp);
      }
    },

    isSuperAdmin() {
      if (!this.currentUser || !this.currentUser.user_groups) return false;
      return this.currentUser.user_groups.find((item) => item.id == 1);
    },

    endPointPageTemplate() {
      return "pages/admin/" + SiteService.getActiveSiteId() + "/pageTemplate/";
    },

    getChilds(childId) {
      return this.pageCollection.filter((page) => page.page_id == childId);
    },

    changeFixed(element) {
      element.fixed = !element.fixed;
      let xxx = this.list;
      this.list = [];
      this.list = xxx;
    },

    translate(item) {
      if (item.translations[this.selectedLocale.lang]) {
        return item.translations[this.selectedLocale.lang].name;
      }
      console.log("item.translations[0].name", item);
      let objKeys = Object.keys(item.translations);
      if (!objKeys || objKeys.length == 0) return "";
      return item.translations[objKeys[0]].name;
    },
  },

  mounted() {
    this.fetchPageTemplate();
    // this.fetchProduct();
    this.setTemplate("[]");
    this.setTranslatedAttributes();

    // this.generateTranslationArray(i18n.messages, this.selectedLocale.lang, []);
  },
};
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
  padding-left: 0;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.sort-name {
  margin-left: 10px;
}

.active {
  color: black;
}
</style>
