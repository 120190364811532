<template>
  <v-dialog
    v-model="customFieldEditorData.dialog"
    max-width="850px"
    persistent
    :retain-focus="false"
  >
    <v-card>
      <v-card-title class="headline">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline"
              >{{ $t("FORM_INPUT_NAMES.editing_custom_field") }}
            </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col v-if="customField" cols="12" sm="6" md="3">
            <v-text-field
              label="customFieldName"
              v-model="customField.customFieldName"
            ></v-text-field>
          </v-col>

          <v-col
            v-if="
              customField &&
              customField.config &&
              customFieldType &&
              customFieldType.config &&
              customFieldType.config.label
            "
            cols="12"
            sm="6"
            md="6"
          >
            <v-combobox
              :items="translationArray"
              v-model="customField.config.label"
              :return-object="false"
              label="label"
            >
              <template slot="selection" slot-scope="data">
                {{ $t(data.item) }}
              </template>
            </v-combobox>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="3">
            <v-select
              v-if="customField"
              v-model="customFieldType"
              :items="customFieldTypes"
              label="Type"
              return-object
              @change="handleSelectCustomFieldType"
              :disabled="!canSelectType"
            >
              <template v-slot:item="{ item }">
                {{ item.config.type }}
              </template>
              <template v-slot:selection="{ item }">
                <span class="navi-text">{{ item.config.type }}</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="
              customField &&
              customField.config &&
              customFieldType &&
              customFieldType.config &&
              customFieldType.config.questionLabel
            "
            cols="12"
            sm="6"
            md="6"
          >
            <v-text-field
              label="questionLabel"
              v-model="customField.config.questionLabel"
            ></v-text-field>
          </v-col>

          <v-col
            v-if="
              customField &&
              customField.config &&
              customFieldType &&
              customFieldType.config &&
              customFieldType.config.answerLabel
            "
            cols="12"
            sm="6"
            md="6"
          >
            <v-text-field
              label="answerLabel"
              v-model="customField.config.answerLabel"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="
              customField &&
              customField.config &&
              customFieldType &&
              customFieldType.config &&
              customFieldType.config.height
            "
            cols="12"
            sm="3"
            md="3"
          >
            <v-text-field
              label="height"
              v-model="customField.config.height"
            ></v-text-field>
          </v-col>

          <v-col
            v-if="
              customField &&
              customField.config &&
              customFieldType &&
              customFieldType.config &&
              customFieldType.config.uploadImageMaxWidth
            "
            cols="12"
            sm="3"
            md="3"
          >
            <v-text-field
              label="uploadImageMaxWidth"
              v-model="customField.config.uploadImageMaxWidth"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            v-if="
              customField &&
              customField.config &&
              customFieldType &&
              customFieldType.config &&
              customFieldType.config.selectButtonText
            "
            cols="12"
            sm="5"
            md="5"
          >
            <v-text-field
              label="selectButtonText"
              v-model="customField.config.selectButtonText"
            ></v-text-field>
          </v-col>

          <v-col
            v-if="
              customField &&
              customField.config &&
              customFieldType &&
              customFieldType.config &&
              customFieldType.config.resolution
            "
            cols="12"
            sm="2"
            md="2"
          >
            <v-text-field
              label="resolution"
              v-model="customField.config.resolution"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="
              customField &&
              customField.config &&
              customFieldType &&
              customFieldType.config &&
              customFieldType.config.initialColumnClass
            "
            cols="12"
            sm="5"
            md="5"
          >
            <v-text-field
              label="initialColumnClass"
              v-model="customField.config.initialColumnClass"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">{{
          $t("FORMS.cancel")
        }}</v-btn>
        <v-btn color="primary" @click="handleSaveModalForm">
          {{ $t("FORMS.save") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import i18n from "@/core/plugins/vue-i18n";
import i18nService from "@/core/services/i18n.service.js";

const CUSTOM_FIELD_TYPES = [
  {
    customFieldName: "divider",
    config: {
      type: "divider",
      label: "FORMS.block1",
    },
  },
  {
    customFieldName: "block1_name",
    config: {
      type: "text",
      label: "FORM_INPUT_NAMES.title",
    },
  },
  {
    customFieldName: "ckeditor",
    config: {
      type: "ckeditor",
      label: "FORM_INPUT_NAMES.text",
      height: "550",
      editorConfig: "editorConfig",
      uploadImageMaxWidth: "500",
    },
  },
  {
    customFieldName: "main_image",
    config: {
      type: "media_selector",
      maxFiles: 1,
      inputFields: [
        {
          name: "title",
          label: "FORM_INPUT_NAMES.title",
          type: "text",
        },
        {
          name: "url",
          label: "FORM_INPUT_NAMES.url",
          type: "text",
        },
        {
          name: "alt_text",
          label: "FORM_INPUT_NAMES.alt_text",
          type: "text",
        },
      ],
      selectButtonText: "FORM_INPUT_NAMES.select_image",
      resolution: "640x480",
      initialColumnClass: "col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12",
    },
  },
  {
    customFieldName: "main_gallery",
    config: {
      type: "media_selector",
      maxFiles: -1,
      inputFields: [
        {
          name: "title",
          label: "FORM_INPUT_NAMES.title",
          type: "text",
        },
        {
          name: "url",
          label: "FORM_INPUT_NAMES.url",
          type: "text",
        },
        {
          name: "alt_text",
          label: "FORM_INPUT_NAMES.alt_text",
          type: "text",
        },
      ],
      selectButtonText: "FORM_INPUT_NAMES.gallery",
      resolution: "640x480",
    },
  },
  {
    customFieldName: "button_more",
    config: {
      type: "button",
      label: "Mutass többet",
    },
  },
  {
    customFieldName: "datePicker",
    config: {
      type: "date_picker",
      label: "FORM_INPUT_NAMES.date",
      initialColumnClass: "col-xl-2 col-lg-3 col-md-3 col-sm-6 col-xs-12",
    },
  },
  {
    customFieldName: "dateTimePicker",
    config: {
      type: "date_time_picker",
      label: "FORM_INPUT_NAMES.date_time",
      initialColumnClass: "col-xl-2 col-lg-3 col-md-3 col-sm-6 col-xs-12",
    },
  },
  {
    customFieldName: "dateRange",
    config: {
      type: "date_range_picker",
      label: "FORM_INPUT_NAMES.dateRange",
      initialColumnClass: "col-xl-2 col-lg-3 col-md-3 col-sm-6 col-xs-12",
    },
  },
  {
    customFieldName: "timeLine",
    config: {
      type: "accordion",
      label: "FORMS.timeLine",
      questionLabel: "FORMS.year",
      answerLabel: "FORMS.text",
    },
  },
  {
    customFieldName: "external_references",
    config: {
      type: "external_references",
      maxFiles: -1,
      inputFields: [
        {
          name: "title",
          label: "FORM_INPUT_NAMES.title",
          type: "text",
        },
        {
          name: "date",
          label: "FORM_INPUT_NAMES.date",
          type: "datePicker",
        },
        {
          name: "url",
          label: "FORM_INPUT_NAMES.url",
          type: "text",
        },
      ],
      selectButtonText: "FORM_INPUT_NAMES.select_ExternalReference",
      initialColumnClass: "",
    },
  },
];

export default {
  name: "CustomFieldEditorModalDialog",
  props: ["customFieldEditorData"],

  data() {
    return {
      column_class: "col-6 col-md-3 col-lg-4 col-xl-6",
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      customFieldTypes: [...CUSTOM_FIELD_TYPES],
      customFieldType: null,
      customField: {},
      canSelectType: true,
      dialogDelete: false,
      i18n: i18n,
      translationArray: [],
    };
  },

  watch: {
    customFieldEditorData: {
      deep: true,
      handler(value) {
        if (value.customField) {
          this.customField = {};
          this.customField = Object.assign({}, value.customField);
          this.customField.config = Object.assign({}, value.customField.config);
          this.customField.customFieldName = value.customField.customFieldName;
          console.log("this.customField", this.customField);
          this.canSelectType = false;
        } else {
          this.customField = {};
          this.customFieldType = null;
          this.canSelectType = true;
        }
        if (this.customField && this.customField.config) {
          this.customFieldType = this.customFieldTypes.find(
            (item) => item.config.type == this.customField.config.type
          );
        }
      },
    },
  },
  methods: {
    handleSelectCustomFieldType(type) {
      this.customField = Object.assign({}, type);
    },

    handleCloseModalForm() {
      this.$emit("handleCloseCustomFieldEditorModalForm");
    },

    handleSaveModalForm() {
      this.$emit(
        "handleSaveCustomFieldEditorModalForm",
        Object.assign({}, this.customField)
      );
    },

    get_translations_array(json_object, ret_array = [], json_key_parent = "") {
      let json_key = null;
      let first_element = null;
      for (json_key in json_object) {
        if (
          typeof json_object[json_key] === "object" &&
          !Array.isArray(json_object[json_key])
        ) {
          // ret_array.push({
          //   key: json_key_parent + json_key,
          //   value: this.$t(json_key_parent + json_key),
          // });
          this.get_translations_array(
            json_object[json_key],
            ret_array,
            json_key_parent + json_key + "."
          );
        } else if (Array.isArray(json_object[json_key])) {
          ret_array.push({
            value: json_key_parent + json_key,
            text: this.$t(json_key_parent + json_key),
          });
          first_element = json_object[json_key][0];
          if (typeof first_element === "object") {
            this.get_translations_array(
              first_element,
              ret_array,
              json_key_parent + json_key + "."
            );
          }
        } else {
          ret_array.push({
            value: json_key_parent + json_key,
            text: this.$t(json_key_parent + json_key),
          });
        }
      }

      return ret_array;
    },
  },

  mounted() {
    this.translationArray = this.get_translations_array(
      i18n.messages[this.selectedLocale.lang],
      []
    );
  },
};
</script>
